<div class="barra-inicial barra-inicial-celeste">
  <img src="../../assets/images/dialog/cruz.png" alt="Cerrar" id="cruz" (click)="cerrarDialogo()">
</div>

<h1 mat-dialog-title class="tituloDialog" style="padding-left: 0%;">Tu turno fue reservado con éxito  </h1>

<mat-divider class="divisor"></mat-divider>

<div mat-dialog-content>      
      <div [hidden]="!tieneWebCheckIn">
        <ul>
          <li>
            El día del turno, dos horas antes del horario estimado, se habilita la Admisión Digital para que puedas registrar tu presente sin necesidad de pasar por el sector de Cajas.
          </li>
          <li>
            En caso de que la Admisión Digital no se habilite, deberás pasar por el sector de Cajas 30 minutos antes del horario estimado del turno, presentando DNI y Carnet de la cobertura.
          </li>
        </ul>
        <!-- <p><strong>En caso de presentar sintomas de COVID al momento del turno deber&aacute; cancelarlo y dirigirse al servicio de Guardia.</strong></p> -->
      </div>
      <div class="modal-body" [hidden]="tieneWebCheckIn">
        <ul>
          <li>
            Recordá que deberás pasar por Cajas 15 minutos antes del horario estimado del turno, presentando DNI y Carnet de la Cobertura.
          </li>
        </ul>
        <!-- <p><strong>En caso de presentar sintomas de COVID al momento del turno deber&aacute; cancelarlo y dirigirse al servicio de Guardia.</strong></p> -->
      </div>      
</div>

<mat-divider class="divisor"></mat-divider>

<div mat-dialog-actions class="d-flex justify-content-center">
  <!-- <button mat-raised-button 
          color="primary" 
          [mat-dialog-close]="true"
          > Aceptar
  </button> -->

  <div 
        class="boton-dialog boton-confirmar"
        color="primary"        
        [mat-dialog-close]="true"> Aceptar
    </div>
</div>   
